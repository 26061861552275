import React, { createContext, useState, useEffect, useMemo } from 'react';
import { IntlProvider, createIntl } from 'react-intl';
import moment from 'moment';

import {
  SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
} from '../../constants/locales';

function importTranslations(locale) {
  let file = 'sv.json';
  switch (locale) {
    case 'sv':
      file = 'sv.json';
      break;
    case 'en':
      file = 'en.json';
      break;
    default:
      file = 'sv.json';
      break;
  }
  return import(`../../compiled-lang/${file}`);
}

const initialIntl = createIntl({
  locale: DEFAULT_LOCALE,
  messages: {},
});

export const InternationalizationContext = createContext(initialIntl);

export function InternationalizationProvider({ children }) {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [messages, setMessages] = useState({});
  const [intl, setIntl] = useState(initialIntl);

  const usersBrowserLocale = DEFAULT_LOCALE;

  const setAndImportLocale = async (newLocale) => {
    const translations = await importTranslations(newLocale);
    setMessages(translations);
    setLocale(newLocale);
    moment.locale(newLocale);
  };

  const setInitialLocale = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryLocale = urlParams.get('locale');

    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      setAndImportLocale(savedLocale);
    } else if (queryLocale && SUPPORTED_LOCALES.includes(queryLocale)) {
      setAndImportLocale(queryLocale);
    } else if (SUPPORTED_LOCALES.includes(usersBrowserLocale)) {
      setAndImportLocale(usersBrowserLocale);
    } else {
      setAndImportLocale(DEFAULT_LOCALE);
    }
  };

  useEffect(() => {
    setInitialLocale();
  }, []);

  useEffect(() => {
    if (locale === undefined) return;
    localStorage.setItem('locale', locale);
    const localIntl = createIntl({ locale, messages });
    setIntl(localIntl);
  }, [locale, messages]);

  const fm = (id, defaultMessage, options = null) => {
    if (intl && intl.formatMessage) {
      return intl.formatMessage({ id, defaultMessage }, options);
    }
    return defaultMessage;
  };

  const value = useMemo(() => ({
    ...intl,
    setAndImportLocale,
    fm,
  }), [intl]);

  return (
    <InternationalizationContext.Provider value={value}>
      <IntlProvider
        messages={messages}
        locale={locale || DEFAULT_LOCALE}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </InternationalizationContext.Provider>
  );
}
